import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const BioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 24px;
  padding: 16px 0px;
`

const BioResume = styled.p`
  font-family: 'Merriweather';
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => get(theme, 'textColor')};
  margin-left: 8px;

  & > strong {
    font-weight: bolder;
  }

  & > a {
    color: ${({ theme }) => get(theme, 'titleColor')};
  }
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing 'siteMetadata' in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <BioContainer>
      <StaticImage
        src='../../../images/profile-pic.png'
        alt='Profile picture'
        style={{ minWidth: 50, borderRadius: '100%' }}
        imgStyle={{ minWidth: 50, borderRadius: '100%' }}
        width={50}
        height={50}
        formats={['auto', 'webp', 'avif']}
        quality={95} />
      {author?.name && (
        <BioResume>
          Written by <strong>{author.name}</strong> {author?.summary || null}
          {` `}
          <a href={`https://twitter.com/${social?.twitter || ``}`}>
            You should follow them on Twitter
          </a>
        </BioResume>
      )}
    </BioContainer>
  )
}

export default Bio
